@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Regular.eot');
    src: url('SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-Regular.woff2') format('woff2'),
        url('SourceSansPro-Regular.woff') format('woff'),
        url('SourceSansPro-Regular.ttf') format('truetype'),
        url('SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Bold.eot');
    src: url('SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-Bold.woff2') format('woff2'),
        url('SourceSansPro-Bold.woff') format('woff'),
        url('SourceSansPro-Bold.ttf') format('truetype'),
        url('SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

